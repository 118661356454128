import React from "react"
import Breadcrumbs from "../../components/breadcrumbs"
import { useStaticQuery, graphql } from "gatsby"
import FeaturedImg from "../../components/featuredImg"
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'
import Layout from "../../components/layout"
import Button from "../../components/button"
import { ReactComponent as QuoteMark } from './MIM_Icon_Quote.svg'
import pages from "../../data/pages"

import './testimonials.scss'

const Testinomials = () => {

  const data = useStaticQuery(graphql`
    query {
      featuredImgDesktop: file(
        relativePath: { eq: "20-MIM-0008_Secondary_CA-Testimonial-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      featuredImgMobile: file(
        relativePath: { eq: "20-MIM-0008_Mobile-11.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const testinomials = [
    {
      // img: `https://via.placeholder.com/150`,
      quote: `I tell people you’re not the cheapest, but you are the best! Equipment is always nice and updated, you guys are available when I need you, No complaints whatsoever!`,
      author: "Neal Travis, NVF Inc",
    },
    {
      // img: `https://via.placeholder.com/150`,
      quote: "You guys do an awesome job, couldn’t be happier!",
      author: "Dustin Hardy, Maple Row",
    },
    {
      // img: `https://via.placeholder.com/150`,
      quote: "What a roadshow!",
      author: "Bas DeJong, Hickory Gables",
    },
  ]
  return <Layout
    className="secondary-page"
  >
    <Breadcrumbs currentItem={pages[3]}></Breadcrumbs>
    <Container className="main-content">
      <FeaturedImg data={data} title="Testimonials" ></FeaturedImg>
      <section>
        <article>
          <p>What started out as a single tractor and tanker, has grown into the number one manure handling service in the state of Michigan. At MidMichigan Ag Service, we strive to ensure our customers are satisfied and being environmentally responsible. How are we doing? We want to hear from you!</p>

          <Button style={{ width: "fit-content" }} label="SUBMIT A TESTIMONIAL" to="../" className="smaller-text-mobile"></Button>
        </article>
      </section>
      <section style={{ marginBottom: '10rem' }}>
        <Table striped hover size="md" className="testinomial-table">
          <tbody>
            {testinomials.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <section>
                      {item.img ? (
                        <img
                          src="https://via.placeholder.com/120"
                          style={{ marginRight: "1rem", alignSelf: "center" }}
                          alt="author"
                        ></img>
                      ) : null}
                      <div>
                        <QuoteMark></QuoteMark>
                        <p>
                          <span
                            dangerouslySetInnerHTML={{ __html: item.quote }}
                          ></span>
                          <strong
                            style={{
                              display: "block",
                              marginTop: "0.5rem",
                              textTransform: "uppercase",
                            }}
                          >
                            {item.author}
                          </strong>
                        </p>
                      </div>
                    </section>
                    <span></span>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </section>
    </Container>
  </Layout>
}

export default Testinomials